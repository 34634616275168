import * as React from "react"

const Introduction = ({content}) => (
  <div className='introduction p40'>
    <div className='w-70 m-100'> 
      <div className='h1 m-h2 regular max-900 white-space' dangerouslySetInnerHTML={{ __html:content }} /> 
    </div>
  </div>
)

export default Introduction
