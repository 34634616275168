import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const MoreContent = ({content, image}) => (
  <div className='more--content p40 flex m-wrap'>
    <div className='w-70 m-100'>  
      <div className='max-750 uppercase white-space'>
        {content}
      </div>
    </div>
    <div className='w-30 m-100 m-mt40'>
      <div className='max-400 m-max-250 mla m-m0'>
        <div className='ratio-2-1 bg-grey pos-rel'>
          <GatsbyImage className='bg-image' image={image?.gatsbyImageData} alt='About Us' />
        </div>
      </div>
    </div>
  </div>
)

export default MoreContent
