import * as React from "react"

class EnquiryForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formSubmitted: false,
      message: ""

    }
    
  }
  componentDidMount() {
    document.querySelector("form").addEventListener("submit", this.handleSubmit)
  }
  

  handleSubmit = e => {
    e.preventDefault()
    let myForm = document.getElementById("contact")
    let formData = new FormData(myForm)
    if(formData.get('First Name') && formData.get('Email')){
      console.log('test fetch')
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
      .then(() => {
        const form = document.querySelector("form")
        form.classList.add("fade-out")
        setTimeout(() => {
          this.setState({ formSubmitted: true },()=>{
            const thankYou = document.querySelector("#thank-you")
            setTimeout(()=>{
              thankYou.classList.add("fade-in-thank-you")
            },100)
           
          })
        }, 1250)
      })
    .catch(error => console.error(error))
    document.querySelector("form").addEventListener("submit", this.handleSubmit);
    }
  }

  render() {
    const formSubmitted = this.state.formSubmitted

    var classes = 'p20 w-100  pl0 mb20 bb1 m-m0 input'
    
    if(!formSubmitted){
    return (
      <div className="p40 pt0 fade-in" data-sal data-sal-delay='400'>
        <form method="POST" data-netlify="true" id="contact" className="contact--form">
            <input type="hidden" name="form-name" value="contact" />
            <div className='w-100 flex m-wrap'>
              <input required={true} name="First Name" type="text" className={classes+' mr20'} placeholder="First Name"/>
              <input required={true} name="Last Name" type="text" className={classes} placeholder="Last Name"/>
            </div> 
            <div className='flex m-wrap'>
              <input required={true} name="Email" type="text" className={classes+' mr20'} placeholder="Email" />
              <input name="Number" type="text" className={classes} placeholder="Number" />
            </div>
            
            <div className=''>
              <div className='flex'>
                <input name="Enquiry Type" type="text" className={classes} placeholder="Enquiry Type"/>
              </div>
            </div>
            
            <div className='text-area flex mt20'>
              <textarea className="textarea p20 input w-100" onChange={(e) => this.setState({message:e.target.value})} value={this.state.message} name="message" placeholder='Message...' type="text" id="message" />
            </div>

            <div className="submit mt20">
              <button type="submit" className="submit-button mt20">Submit</button>
            </div>
          </form>
      </div>
    )}else{
      return <div className="form-section mt40 thank-you p40 white h2 regular" id="thank-you"> Thank You for submitting. We'll be in touch soon</div>
    }
  }
}

export default EnquiryForm
