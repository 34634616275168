import * as React from "react"
import Layout from "../components/layout"
import ImageBanner from "../components/imageBanner"
import Introduction from "../components/introduction"
import MoreContent from "../components/moreContent"
import Spacer from "../components/spacer"
import Title from "../components/title"
import Form from "../components/form"
import Seo from "../components/seo"
import { graphql } from "gatsby" 

const About = ({data}) => {
  var page = data.page;
  return (
  <Layout header='dark'>
    <div className='pth m-show'/>
    <Spacer />
    <Title title='About'/>
    <Introduction content={page.introduction} />
    <Spacer />
    <MoreContent content={page.moreContent} image={page.moreImage} />
    <Spacer />
    <div className='bg-slate pt40'>
      <Title className='white' title='Contact Us'/>
      <Spacer />
      <Form/>
      <Spacer />
    </div>
  </Layout>
)
}

export const Head = () => <Seo title="About Foster" />

export default About

export const query = graphql`
  query AboutPage {
    page:datoCmsAbout {
      heroTitle
      heroImage {
        gatsbyImageData
      }
      introduction
      moreContent
      moreImage {
        gatsbyImageData
      }
    }
    listings:allDatoCmsListing {
      edges {
        node {
          title
          price
          caption
          featureImage {
            gatsbyImageData
          }
        }
      }
    }
  }
`